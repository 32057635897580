import React, { lazy, Suspense } from "react";
import { Routes, Route, HashRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AuthProvider } from "./AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import { FullScreenLoader } from "./components/Preloader";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./components/Modals/Password"));
const WorkspaceChat = lazy(() => import("./pages/WorkspaceChat"));
const NewWorkspaceModal = lazy(() =>
  import("./components/Modals/NewWorkspace")
);

export default function App() {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <AuthProvider>
        <Router>
          <div className="w-full h-full flex flex-col">
            <div className="flex-grow flex relative overflow-hidden">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<PrivateRoute Component={Home} />} />
                <Route
                  path="/workspace/:slug"
                  element={<PrivateRoute Component={WorkspaceChat} />}
                />
              </Routes>
              <NewWorkspaceModal />
            </div>
          </div>
          <ToastContainer />
        </Router>
      </AuthProvider>
    </Suspense>
  );
}
