import { API_BASE } from "./constants";

export default {
  home: () => {
    return "/";
  },
  login: () => {
    return "/login";
  },
  whatsapp: () =>
    "https://api.whatsapp.com/send?phone=5521997472441&text=Oi,%20F%C3%A1tima",
  telegram: () => "https://t.me/aosfatosfatimabot",
  aosfatos: () => "https://www.aosfatos.org/",
  github: () => {
    return "https://github.com/Mintplex-Labs/anything-llm";
  },
  discord: () => {
    return "https://discord.com/invite/6UyHPeGZAC";
  },
  docs: () => {
    return "https://docs.useanything.com";
  },
  mailToMintplex: () => {
    return "mailto:team@mintplexlabs.com";
  },
  hosting: () => {
    return "https://my.mintplexlabs.com/aio-checkout?product=anythingllm";
  },
  feedback: () => {
    return "https://forms.gle/gwVMzAL8kFyj6cPh8";
  },
  workspace: {
    chat: (slug) => {
      return `/workspace/${slug}`;
    },
  },
  exports: () => {
    return `${API_BASE.replace("/api", "")}/system/data-exports`;
  },
  apiDocs: () => {
    return `${API_BASE}/docs`;
  },
  admin: {
    system: () => {
      return `/admin/system-preferences`;
    },
    users: () => {
      return `/admin/users`;
    },
    invites: () => {
      return `/admin/invites`;
    },
    workspaces: () => {
      return `/admin/workspaces`;
    },
    chats: () => {
      return "/admin/workspace-chats";
    },
    appearance: () => {
      return "/admin/appearance";
    },
    apiKeys: () => {
      return "/admin/api-keys";
    },
  },
};
